<template>
  <a-layout class='index animated fadeIn'>
    <a-card>
      <a-form ref='form' :model='search' :style="{'marginBottom':'20px'}" :initialValues="search" :onFinish='pageSearchChange' layout='inline'>
        <a-form-item label="关键词" name='keywords'>
          <a-input v-model:value="search.keywords" style="width:250px" placeholder="请输入品牌商编号/账号"></a-input>
        </a-form-item>
        <a-form-item label="品牌商状态" name='status'>
          <a-select v-model:value="search.status" :style="{width:'180px'}" placeholder="全部">
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option :value="1">
              正常
            </a-select-option>
            <a-select-option :value="0">
              禁用
            </a-select-option>
            <a-select-option :value="-1">
              已过期
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="品牌商费用状态" name='time_type'>
          <a-select v-model:value="search.time_type" :style="{width:'180px'}" placeholder="全部">
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option :value="1">
              正常期
            </a-select-option>
            <a-select-option :value="2">
              续费期
            </a-select-option>
            <a-select-option :value="3">
              已过期
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" htmlType='submit'>搜索</a-button>
        </a-form-item>
      </a-form>
      <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='data' class="tableFixBox" :scroll="{ x: 'max-content'}">
        <template v-slot:signing_time="{ record }">
          <p> {{record.signing_start_time}}</p>
          <p> {{record.signing_end_time}}</p>
        </template>
        <template v-slot:action="{ record }">
          <a-dropdown>
            <a class="ant-dropdown-link"> 操作
              <DownOutlined />
            </a>
            <template v-slot:overlay>
              <a-menu>
                <a-menu-item>
                    <a href="javascript:;" v-has:brandRechargeMore="()=> {$router.push('/brand/brand_list/detail?type=list&id='+record.id)}" >详情</a>
                </a-menu-item>
                <a-menu-item v-if="record.is_sys_brand==1">
                  <a v-has:brandRechargeLog="()=>checkLog(record)" href="javascript:;">充值明细</a>
                </a-menu-item>
                <a-menu-item>
                  <a v-has:brandRecharge="()=>brandRecharge(record,1)" href="javascript:;">充值</a>
                </a-menu-item>
                <a-menu-item v-if="record.is_sys_brand==1">
                  <a v-has:brandDeduct="()=>brandRecharge(record,2)" href="javascript:;">扣费</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-table>
      <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange" @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
    </a-card>
  </a-layout>
  <a-modal v-model:visible="visible" :title="brandItem.type==1?'品牌商充值':'品牌商扣款'" centered @ok="handleOk()" @cancel='price = ""' :width="500" :confirm-loading="confirmLoading">
    <a-form :label-col="{span:2}" :wrapper-col="{span:15}">
      <a-form-item label="金额" name='keywords'>
        <a-input-number v-model:value="price" :placeholder='brandItem.type==1?"请输入要充值的金额":"请输入要扣款的金额"' style='width:100%' v-inputFilter:price></a-input-number>
      </a-form-item>
      <a-form-item label="备注">
        <a-textarea v-model:value="mark" showCount :maxlength="100" />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="logVisible" title="充值明细" centered @ok="logVisible=false" @cancel='logVisible = false' :width="1200" :confirm-loading="confirmLoading" :footer="null">
    <a-form layout='inline' class="m-b-10">
      <a-form-item label="类型" name="">
        <a-select v-model:value="logSearch.type" :style="{ width: '180px' }" placeholder="全部" @change="logsPageSearchChange">
          <a-select-option value="">全部</a-select-option>
          <a-select-option :value="item.type" v-for='item in typeLits' :key='item'>{{item.name}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="操作时间">
        <a-range-picker valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' @change="onChange" />
      </a-form-item>
    </a-form>
    <a-table :pagination='false' :loading='listLoading' bordered :columns='logsColumns' :data-source='logsList'>
    </a-table>
    <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper :current="logSearch.page" :page-size="logSearch.limit" :total="logSearch.total" @change="logsPageCurrentChange" @showSizeChange="logsPageSizeChange" :show-total="total => `总数: ${total} `" />
  </a-modal>
</template>
<script>
import { DownOutlined } from "@ant-design/icons-vue";
import { ref, onMounted, inject, reactive, toRefs, h } from "vue";
import { message, Modal } from "ant-design-vue";
import { $iscode } from "@/utils/app";
import { getBrandtList, setBrandDisable, setBrandRecharge,setBrandDeduct } from "@/api/brand";
import { getBrandBillsList, getBrandBillsType } from "@/api/financial";

// 初始化默认筛选项数值
let defSearch = {
  keywords: "",
  status: "",
  page: 1,
  limit: 10,
  signing_start_time: "",
  signing_end_time: "",
  time_type: "",
};
export default {
  components: { DownOutlined },
  setup(props) {
    // // 初始化 分页信息和筛选项信息
    let search = ref({ ...defSearch });
    // 列表数据和列头配置
    let data = ref([]);
    const state = reactive({
      brandItem: {},
      listLoading: false,
      visible: false,
      visibleC: false,
      total: 1,
      valueRadio: null,
      time: [],
      typeLits: [],
      price: "",
      mark: "",
      commission: "",
      confirmLoading: false,
      buyData: [],
      packages: [],
      logVisible: false,
      brandId: "",
    });
    let columns = [
      {
        title: "品牌商编号",
        dataIndex: "brand_no",
        key: "brand_no",
      },
      {
        title: "公司名称",
        dataIndex: "company",
        key: "company",
      },
      {
        title: "品牌商账号",
        dataIndex: "account",
        key: "account",
      },

      {
        title: "账号余额",
        dataIndex: "balance",
        key: "balance",
      },
      {
        title: "有效期",
        dataIndex: "signing_time",
        key: "signing_time",
        slots: { customRender: "signing_time" },
      },
      {
        title: "佣金(元)",
        dataIndex: "commission_per_order",
        key: "commission_per_order",
      },
      {
        title: "状态",
        dataIndex: "status_text",
        key: "status_text",
      },
      {
        title: "微信授权",
        dataIndex: "wechat_auth_text",
        key: "wechat_auth_text",
      },
      {
        title: "认证状态",
        dataIndex: "is_certificate_text",
        key: "is_certificate_text",
      },
      {
        title: "所属代理商",
        dataIndex: "agent_company",
        key: "agent_company",
        customRender: ({ text, record }) => {
          return record.agent_company ? record.agent_company : "-";
        },
      },
      {
        title: "操作",
        key: "action",
        fixed:"right",
        slots: { customRender: "action" },
      },
    ];

    let logsColumns = [
      {
        title: "类型",
        dataIndex: "type_text",
        key: "type_text",
      },
      {
        title: "金额（元）",
        dataIndex: "number",
        key: "number",
      },
      {
        title: "备注",
        dataIndex: "mark",
        key: "mark",
      },
      {
        title: "操作时间",
        dataIndex: "create_time",
        key: "create_time",
      },
      // {
      //   title: "操作人",
      //   dataIndex: "real_price",
      //   key: "real_price",
      // },
    ];

    const $router = inject("$router");
    const $Modal = inject("$Modal");

    // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = { ...search.value, page: 1 };
      initData(search.value);
    };
    // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
      search.value.page = page;
      initData(search.value);
    };
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, size) => {
      search.value.page = 1;
      search.value.limit = size;
      initData(search.value);
    };
    //品牌商禁用
    const brandDisable = (e) => {
      console.log(e);
      $Modal.confirm({
        title: "品牌商" + (e.status ? "禁用" : "启用"),
        content: h("div", {}, [
          h(
            "p",
            { className: "mb10" },
            "您确定要" + (e.status ? "禁用" : "启用") + "该品牌商吗？"
          ),
          h(
            "p",
            { className: "tis" },
            e.status ? "提示：禁用后该品牌商将被直接禁用！请谨慎操作！" : ""
          ),
        ]),
          centered:true,
        onOk: async () => {
          let res = await setBrandDisable(e.id, e.status ? 0 : 1).then(
            (res) => res.data
          );
          if ($iscode(res)) {
            message.success(res.msg);
            initData(search.value);
          } else {
            message.error(res.msg);
          }
        },
      });
    };
    const commissionForm = ref({
      is_charge: 1,
      charge_type: 1,
      charge_percent: "",
      charge_price: "",
      least_order_price: 5,
    });
    //品牌商充值
    const brandRecharge = async (e, type) => {
      // if (type == 1) {
      //   state.visible = true;
      // } else {
        state.visible = true;
      // }
      state.brandItem = {
        ...e,
        type,
      };
    };
    const handleOk = async () => {
      if (state.price == "" &&  state.brandItem.type == 1) {
        message.error("请输入充值金额");
        return false;
      }
      state.confirmLoading = true;
      var json = {};
        var res
      if (state.brandItem.type == 1) {
        json = {
          brand_id: state.brandItem.brand_no,
          number: state.price,
          mark:state.mark,
        };
      res = await setBrandRecharge(json).then((res) => res.data);

      } else {
        json = {
          brand_id: state.brandItem.brand_no,
          number: state.price,
          mark:state.mark,

        };
       res = await setBrandDeduct(json).then((res) => res.data);
      }
      state.confirmLoading = false;
      if ($iscode(res)) {
        message.success(res.msg);
        initData(search.value);
        state.visible = false;
        state.visibleC = false;
        commissionForm.value = {
          is_charge: 1,
          charge_type: 1,
          charge_percent: "",
          charge_price: "",
          least_order_price: 5,
        };
      } else {
        message.error(res.msg);
      }
    };

    const initData = async (values) => {
      state.listLoading = true;
      data.value = [];
      try {
        let res = await getBrandtList(values).then((res) => res.data);
        state.listLoading = false;
        if ($iscode(res)) {
          data.value = res.data.data;
          state.total = res.data.total;
        } else {
          message.error(res.message);
        }
      } catch (e) {
        state.listLoading = false;
      }
    };
    const logSearch = ref({
      limit: 5,
      page: 1,
      total: 0,
      type: "",
      keywords: "",
    });

    const logsList = ref([]);
    const checkLog = async (data) => {
      state.brandId = data.brand_no;
      logSearch.value.keywords = data.company;
      let result = await getBrandBillsList(logSearch.value)
        .then((res) => res.data)
        .catch((error) => error);
      logsList.value = result.data.data;
      logSearch.value.total = result.data.total;
      state.logVisible = true;
    };
    // 页面筛选项搜索
    const logsPageSearchChange = async () => {
      logSearch.value = { ...logSearch.value, page: 1 };
      let result = await getBrandBillsList(logSearch.value)
        .then((res) => res.data)
        .catch((error) => error);
      logsList.value = result.data.data;
      logSearch.value.total = result.data.total;
    };
    // 时间筛选
    const onChange = (e) => {
      if (e.length > 0) {
        logSearch.value.start_time = e[0];
        logSearch.value.end_time = e[1];
      } else {
        logSearch.value.start_time = "";
        logSearch.value.end_time = "";
      }
      logsPageSearchChange();
    };
    // 分页当前页切换
    const logsPageCurrentChange = async (page, pageSize) => {
      logSearch.value.page = page;
      let result = await getBrandBillsList(logSearch.value)
        .then((res) => res.data)
        .catch((error) => error);
      logsList.value = result.data.data;
      logSearch.value.total = result.data.total;
    };
    // 分页当前页显示多少条切换
    const logsPageSizeChange = async (current, size) => {
      logSearch.value.page = 1;
      logSearch.value.limit = size;
      let result = await getBrandBillsList(logSearch.value)
        .then((res) => res.data)
        .catch((error) => error);
      logsList.value = result.data.data;
      logSearch.value.total = result.data.total;
    };

    const getType = async () => {
      let res = await getBrandBillsType().then((res) => res.data);
      if ($iscode(res)) {
        state.typeLits = res.data;
      } else {
        message.error(res.msg);
      }
    };
    onMounted(() => {
      initData(search.value);
      getType();
    });
    return {
      ...toRefs(state),
      search,
      data,
      columns,
      pageSearchChange,
      logsPageSearchChange,
      pageCurrentChange,
      pageSizeChange,
      onChange,
      brandDisable,
      brandRecharge,
      handleOk,
      commissionForm,
      logsColumns,
      checkLog,
      logsList,
      logSearch,
      logsPageSizeChange,
      logsPageCurrentChange,
    };
  },
};
</script>
<style>
.tis {
  color: #f5222d;
}
</style>